<template>
    <div>
        <div class="card">
            <h5>Germal Panel</h5>
            <TabView>
                <TabPanel header="ROI Panel">
                    <div class="grid p-fluid">
                        <div class="col-12 lg:col-6">
                            <div class="card">
                                <h5>Panel 1</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/1/after_roi_web_view.png" alt="Image" width="250" preview />
                                </div>
                            </div>
                            <div class="card">
                                <h5>Panel 3</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/3/after_roi_web_view.png" alt="Image" width="250" preview />
                                </div>
                            </div>
                            <div class="card">
                                <h5>Panel 5</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/5/after_roi_web_view.png" alt="Image" width="250" preview />
                                </div>
                            </div>
                            <div class="card">
                                <h5>Panel 7</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/7/after_roi_web_view.png" alt="Image" width="250" preview />
                                </div>
                            </div>                       
                        </div>  
                        <div class="col-12 lg:col-6">
                            <div class="card">
                                <h5>Panel 2</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/2/after_roi_web_view.png" alt="Image" width="250" preview />
                                </div>
                            </div>
                            <div class="card">
                                <h5>Panel 4</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/4/after_roi_web_view.png" alt="Image" width="250" preview />
                                </div>
                            </div> 
                            <div class="card">
                                <h5>Panel 6</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/6/after_roi_web_view.png" alt="Image" width="250" preview />
                                </div>
                            </div>
                            <div class="card">
                                <h5>Panel 8</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/8/after_roi_web_view.png" alt="Image" width="250" preview />
                                </div>
                            </div>                       
                        </div>                          
                    </div>
                </TabPanel>
                <TabPanel header="3 degree Ployfit">
                    <div class="grid p-fluid">
                        <div class="col-12 lg:col-6">
                            <div class="card">
                                <PloyFit title="Panel 1"/>
                            </div>
                        </div>
                        <div class="col-12 lg:col-6">
                            <div class="card">
                                <PloyFit title="Panel 8"/>
                            </div>
                        </div>
                    </div> 
                </TabPanel>
                <TabPanel header="Pixel Graph">
                    <div class="grid p-fluid">
                        <div class="col-12 lg:col-4">
                            <div class="card">
                                <h5>Panel 1</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/1/hist_plot_2.png" alt="Image" width="350" preview />
                                </div>
                            </div>
                            <div class="card">
                                <h5>Panel 4</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/4/hist_plot_2.png" alt="Image" width="350" preview />
                                </div>
                            </div>
                            <div class="card">
                                <h5>Panel 7</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/7/hist_plot_2.png" alt="Image" width="350" preview />
                                </div>
                            </div>
                                             
                        </div> 
                        <div class="col-12 lg:col-4">
                            <div class="card">
                                <h5>Panel 2</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/2/hist_plot_2.png" alt="Image" width="350" preview />
                                </div>
                            </div>
                            <div class="card">
                                <h5>Panel 5</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/5/hist_plot_2.png" alt="Image" width="350" preview />
                                </div>
                            </div>
                            <div class="card">
                                <h5>Panel 8</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/8/hist_plot_2.png" alt="Image" width="350" preview />
                                </div>
                            </div>
                                             
                        </div>  
                        <div class="col-12 lg:col-4">
                            <div class="card">
                                <h5>Panel 3</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/3/hist_plot_2.png" alt="Image" width="350" preview />
                                </div>
                            </div>
                            <div class="card">
                                <h5>Panel 6</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/6/hist_plot_2.png" alt="Image" width="350" preview />
                                </div>
                            </div>
                                     
                        </div>  
                                             
                    </div>         

                    
                </TabPanel>
                <TabPanel header="L* Image Preview">
                    <div class="grid p-fluid">
                        <div class="col-12 lg:col-6">
                            <div class="card">
                                <h5>Panel 1</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/1/new_image_l.png" alt="Image" width="250" preview />
                                </div>
                            </div>
                            <div class="card">
                                <h5>Panel 3</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/3/new_image_l.png" alt="Image" width="250" preview />
                                </div>
                            </div>
                            <div class="card">
                                <h5>Panel 5</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/5/new_image_l.png" alt="Image" width="250" preview />
                                </div>
                            </div>
                            <div class="card">
                                <h5>Panel 7</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/7/new_image_l.png" alt="Image" width="250" preview />
                                </div>
                            </div>                       
                        </div>  
                        <div class="col-12 lg:col-6">
                            <div class="card">
                                <h5>Panel 2</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/2/new_image_l.png" alt="Image" width="250" preview />
                                </div>
                            </div>
                            <div class="card">
                                <h5>Panel 4</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/4/new_image_l.png" alt="Image" width="250" preview />
                                </div>
                            </div> 
                            <div class="card">
                                <h5>Panel 6</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/6/new_image_l.png" alt="Image" width="250" preview />
                                </div>
                            </div>
                            <div class="card">
                                <h5>Panel 8</h5>
                                <div class="flex justify-content-center">
                                    <Image src="images/test/output/8/new_image_l.png" alt="Image" width="250" preview />
                                </div>
                            </div>                       
                        </div>                          
                    </div>         

                    
                </TabPanel>
                <TabPanel header="Compare All">
                    <div class="grid p-fluid">
                        <div class="col-12 lg:col-6">

                            <div class="card">
                                <h5>Application Hiding Bar Chart</h5>
                                <Chart type="bar" :data="stackedData" :options="stackedOptions" />
                            </div>
                            
                        </div> 
                        <div class="col-12 lg:col-6">
                            <div>
                                <DataTable :value="products" responsiveLayout="scroll">
                                    <Column field="panel" header="Panel"></Column>
                                    <Column field="fh" header="Full"></Column>
                                    <Column field="perc" header="Perceived"></Column>
                                    <Column field="sh" header="Some"></Column>
                                    <Column field="ph" header="Poor"></Column>
                                    <Column field="nh" header="No"></Column>
                                </DataTable>
                            </div>
                        </div>                           
                    </div>
                </TabPanel>
            </TabView>
        </div>
    </div>    
    
</template>

<script>
import { ref } from 'vue';
import ProductService from '../../../service/ProductService';
import PloyFit from './polyfit.vue'

export default {
    components: {PloyFit},
    data() {
        return {
            products: null
        }
    },
    productService: null,
    created() {
        this.productService = new ProductService();
    },
    mounted() {
        this.productService.getAhProduct().then(data => this.products = data);
    },

    setup() {       

        const stackedData = ref({
            labels: ['1', '2', '3', '4', '5', '6', '7','8'],
            datasets: [{
                type: 'bar',
                label: 'Full',                
                backgroundColor: 'green',
                data: [97.5,79.6,75.7,58.4,18.5,6.1,0.3]
            }, {
                type: 'bar',
                label: 'Perceived',
                backgroundColor: '#66BB6A',                
                data: [2.1,18.2,22,36,38.1,57.1,41.6,2]
            }, {
                type: 'bar',
                label: 'Some',
                backgroundColor: '#FFA726',
                data: [0.3,2.1,2.3,5.4,6.9,22.4,40.5,15.1]
            },{
                type: 'bar',
                label: 'Poor',
                backgroundColor: 'red',
                data: [0,0.1,0.1,0.2,0.3,1.5,7.7,28.3]
            },{
                type: 'bar',
                label: 'No',
                backgroundColor: 'black',
                data: [0,0,0,0,0.1,0.5,3.8,47.3]
            }]
        });
        
        
        const stackedOptions = ref(
            {
                plugins: {
                    tooltips: {
                        mode: 'index',
                        intersect: false
                    },
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        stacked: true,
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        stacked: true,
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            }
        );

		return {  stackedData, stackedOptions }
    }
}
</script>