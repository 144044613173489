<template>
    <div>
        <div class="card">
            <h5>{{title}}</h5>
            <Chart type="line" :data="basicData" :options="basicOptions" />
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    props: ['title'],
    setup() {
        const basicData =  ref(
            {
                labels: ['20000',25000, '30000',35000, '40000',45000, '50000', '60000'],
                datasets: [
                    {
                        label: 'Given',
                        data: [3.4,25.7,30.4,42.9,62.9,75.8,81.7,86.2],
                        fill: false,
                        borderDash: [5, 5],
                        borderColor: '#42A5F5',
                        tension: .1
                    },
                    {
                        label: '3 degree Polyfit',
                        data: [3.37146861,26.0530646,30.16336483,42.73433024,62.76194967,76.10615386,82.23661656,85.57305163],
                        fill: false,
                         borderDash: [10, 10],
                        borderColor: 'red',
                        tension: .1
                    }
                ]
            }
        );

      

        const basicOptions = ref(
            {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            }
        );



		return { basicData, basicOptions }
    }
}
</script>